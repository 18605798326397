



































































































import Vue from "vue";
import InceptionDashboardNumbers from "@/components/recruiter/dashboard/InceptionDashboardNumbers.vue";
import { mapGetters } from "vuex";
import {
  GET_COMPANY_DETAILS,
  GET_USER_DETAILS
} from "@/store/modules/auth/constants";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";
// import { socket } from "@/services/socket.service";

export default Vue.extend({
  name: "InceptionRecruiterDashboard",
  components: {
    InceptionDashboardNumbers
  },
  data() {
    return {
      hello_illustration: require("@/assets/illustrations/waving-hand.svg")
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: GET_USER_DETAILS,
      get_company: GET_COMPANY_DETAILS
    }),
    eng_assessment_version(): string {
      const version =
        this.get_company?.s3_path?.assessment_setting?.candidates_english;
      if (version === AppAssessmentNames.english_v1) return "v1";
      else if (version === AppAssessmentNames.english_v2) return "v2";
      else return "v1";
    },
    annotation_assessment_version(): string {
      const version =
        this.get_company?.s3_path?.assessment_setting?.candidates_annotation;
      if (version === AppAssessmentNames.annotation_v1) return "v1";
      else if (version === AppAssessmentNames.annotation_v2) return "v2";
      else return "v1";
    }
  },
  async mounted() {
    //
  },
  methods: {
    //
  }
});
